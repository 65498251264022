import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from "../components/layout.component"
import Seo from "../components/seo.component"

import spinner from "../images/spinner.png"

import "./custom-page.styles.scss"

const contentful = require('contentful')
const contentfulClient = contentful.createClient({
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN
})

export const query = graphql`
  query {
    contentfulCustomPage(slug: { eq: "term-options" }) {
      title
      bannerImage {
        file {
          url
        }
      }
    }
  }
`

const TermOptionsPage = ({ data }) => {
  const [pageContent, setPageContent] = useState(null)

  useEffect(() => {
    contentfulClient.getEntries({
      content_type: 'workshopPage',
      'fields.slug': 'term-options'
    })
      .then((response) => {
        setPageContent(response.items[0])
      })
      .catch(console.error)
  }, [])


  return (
    <Layout>
      <Seo
        title="Parasky Flute Studios Term Options"
        description="It is the another year of flute lessons at the Parasky Flute Studios and students are welcome to attend lessons in person or online at any point during the current term-please do whatever is best for you!"
      />
      <section
        className="custom-page__hero hero is-medium is-dark"
        style={{
          backgroundImage: data.contentfulCustomPage.bannerImage ? "url(" + data.contentfulCustomPage.bannerImage.file.url + ")" : ""
        }}
      >
        <div className="container">
          <div className="custom-page__hero hero-body is-flex-direction-column is-justify-content-center has-text-shadow">
            <h1 className="title is-size-2 is-size-1-desktop has-text-white is-uppercase has-text-centered">
            {pageContent ? pageContent.fields.title : ""}
            </h1>
          </div>
        </div>
      </section>
      <section className="custom-page__main custom-page__main--text-only">
        {pageContent ? (
          <>

            <section id="intro" className="custom-page__intro">
              <div className="container">
                <div className="columns">
                  <div className="column is-one-quarter">
                    <aside className="menu mt-2">
                      <p className="menu-label">
                        Page Contents
                      </p>
                      <ul className="menu-list is-size-6">
                        <li><a href="#intro">{pageContent.fields.title}</a></li>
                        <li><a href="#section1">{pageContent.fields.section1Title}</a></li>
                        <li><a href="#section2">{pageContent.fields.section2Title}</a></li>
                        <li><a href="#section3">{pageContent.fields.section3Title}</a></li>
                        <li><a href="#section4">{pageContent.fields.section4Title}</a></li>
                        <li><a href="#section5">{pageContent.fields.section5Title}</a></li>
                      </ul>
                    </aside>
                  </div>
                  <div className="column is-three-quarters">
                    <div className="content is-size-6">
                      {pageContent.fields.intro && documentToReactComponents(pageContent.fields.intro)}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {pageContent.fields.section1Body && (
              <section id="section1" className="custom-page__section custom-page__section--alt columns">
                <div className="container">
                  <div className="columns">
                    <div className="column is-one-quarter">
                      <aside className="menu mt-2">
                        <a href="#intro">Back to top</a>
                      </aside>
                    </div>
                    <div className="column is-three-quarters">
                      {pageContent.fields.section1Title && (
                        <div className="content">
                          <h2 className="pb-2">{pageContent.fields.section1Title}</h2>
                        </div>
                      )}
                      <div className="content is-size-6">
                        {pageContent.fields.section1Body &&
                          documentToReactComponents(
                            pageContent.fields.section1Body
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {pageContent.fields.section2Body && (
              <section id="section2" className="custom-page__section columns">
                <div className="container">
                  <div className="columns">
                    <div className="column is-one-quarter">
                      <aside className="menu mt-2">
                        <a href="#intro">Back to top</a>
                      </aside>
                    </div>
                    <div className="column is-three-quarters">
                      {pageContent.fields.section2Title && (
                        <div className="content">
                          <h2 className="pb-2">{pageContent.fields.section2Title}</h2>
                        </div>
                      )}
                      <div className="content is-size-6">
                        {pageContent.fields.section2Body &&
                          documentToReactComponents(
                            pageContent.fields.section2Body
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {pageContent.fields.section3Body && (
              <section id="section3" className="custom-page__section custom-page__section--alt columns">
                <div className="container">
                  <div className="columns">
                    <div className="column is-one-quarter">
                      <aside className="menu mt-2">
                        <a href="#intro">Back to top</a>
                      </aside>
                    </div>
                    <div className="column is-three-quarters">
                      {pageContent.fields.section3Title && (
                        <div className="content">
                          <h2 className="pb-2">{pageContent.fields.section3Title}</h2>
                        </div>
                      )}
                      <div className="content is-size-6">
                        {pageContent.fields.section3Body &&
                          documentToReactComponents(
                            pageContent.fields.section3Body
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {pageContent.fields.section4Body && (
              <section id="section4" className="custom-page__section columns">
                <div className="container">
                  <div className="columns">
                    <div className="column is-one-quarter">
                      <aside className="menu mt-2">
                        <a href="#intro">Back to top</a>
                      </aside>
                    </div>
                    <div className="column is-three-quarters">
                      {pageContent.fields.section4Title && (
                        <div className="content">
                          <h2 className="pb-2">{pageContent.fields.section4Title}</h2>
                        </div>
                      )}
                      <div className="content is-size-6">
                        {pageContent.fields.section4Body &&
                          documentToReactComponents(
                            pageContent.fields.section4Body
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {pageContent.fields.section5Body && (
              <section id="section5" className="custom-page__section custom-page__section--alt columns">
                <div className="container">
                  <div className="columns">
                    <div className="column is-one-quarter">
                      <aside className="menu mt-2">
                        <a href="#intro">Back to top</a>
                      </aside>
                    </div>
                    <div className="column is-three-quarters">
                      {pageContent.fields.section5Title && (
                        <div className="content">
                          <h2 className="pb-2">{pageContent.fields.section5Title}</h2>
                        </div>
                      )}
                      <div className="content is-size-6">
                        {pageContent.fields.section5Body &&
                          documentToReactComponents(
                            pageContent.fields.section5Body
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

          </>
        ) : (
          <div className="custom-page__spinner">
            <img src={spinner} alt="Loading" />
          </div>
        )}
      </section>
    </Layout>
  )
}

export default TermOptionsPage
